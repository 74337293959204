//? Импорт реакт компонентов
import { useState, useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

//? Импорты компонентов
import Navbar from '../Navbar/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import Main from '../Main/Main'

//? Импорт сссылок и страниц
import { adminLinks } from '../../utils/sideLinks'
import TablePage from '../../pages/TablePage/TablePage'
import WalletsPage from '../../pages/WalletsPage/WalletsPage'

//? Импорт контекста
import { useDataContext } from '../../contexts/DataContext/ContextProvider'
import { useAuthContext } from '../../contexts/AuthContext/AuthContext'

//? Импорт функций обращения к API
import { getWallets, getTableData, getBalance } from '../../utils/panelApi'
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage'

function App() {
    //? Объявление контекстных функций
    const { setWalletsData, setTablesData } = useDataContext()
    const { logout, approvedRole, getToken } = useAuthContext()
    const [balance, setBalance] = useState('')

    //? Стейт переменная Сайдбара
    const [sideBarActive, setSideBarActive] = useState(true)
    //? Стейт переменные страниц и ссылок
    const [routePages, setRoutePages] = useState([])
    const [sideBarLinks, setSideBarLinks] = useState(adminLinks)

    //? Функция открытия сайдбара
    function handleSideBarActive() {
        setSideBarActive(!sideBarActive)
    }

    //? Функция связки страниц с ссылками
    function linkPages() {
        const appPages = {
            tablePage: TablePage,
            walletsPage: WalletsPage,
        }

        const connectArray = sideBarLinks
        connectArray.map((item) => {
            item.links.map((link) => {
                if (link.name === 'e-wallet') {
                    link.component = appPages.walletsPage
                } else if (link.name === 'table') {
                    link.component = appPages.tablePage
                }
            })
        })

        return setRoutePages(connectArray)
    }

    function tableDataHandler() {
        getBalance(getToken())
            .then((res) => {
                setBalance(res.message)
            })
            .catch((err) => {
                console.log(err)
            })
        getTableData(getToken())
            .then((res) => {
                setTablesData({ data: res.message })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        linkPages()
    }, [sideBarLinks])

    //? Получение данных при монтировании
    useEffect(() => {
        setWalletsData(getWallets())
        tableDataHandler()
    }, [])
    return (
        <Routes>
            <Route
                exact
                path="/"
                element={
                    <div className="w-full h-[100vh] flex text-baseTextColor">
                        <Sidebar
                            openSideBar={handleSideBarActive}
                            isOpen={sideBarActive}
                            linksArray={sideBarLinks}
                            approvedRole={approvedRole}
                        />
                        <div className=" w-[100%] flex flex-col gap-4 rounded-tl-3xl rounded-bl-3xl bg-base-blue shadow-2xl">
                            <Navbar balance={balance} logout={logout} />
                            <Outlet />
                        </div>
                    </div>
                }
            >
                <Route path="/home/*" element={<Main pagesArray={routePages} approvedRole={approvedRole} />} />
            </Route>
        </Routes>
    )
}

export default App
