import { Link, NavLink } from 'react-router-dom'

function Sidebar({ openSideBar, isOpen, linksArray, approvedRole }) {
    const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5  rounded-lg  bg-button-hover text-md'
    const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md hover:bg-light-gray'
    return (
        <div className={`relative ${isOpen ? 'max-w-[300px]' : 'max-w-[70px]'} w-full  bg-white transition-all`}>
            <button
                className="absolute top-2 right-4 max-w-[40px] w-full py-[20px] rounded-full transition-all hover:bg-button-hover bg-burgerMenu bg-[length:20px_20px] bg-no-repeat bg-center"
                onClick={openSideBar}
            />
            <div
                className={`${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'} w-full py-2 px-4  transition-all`}
            >
                <Link to="/">
                    <div className="max-w-[120px] bg-logo bg-no-repeat bg-contain bg-center py-5 mb-5" />
                </Link>
                <div className="flex flex-col gap-4">
                    {linksArray.map((item, i) => {
                        return (
                            <div key={i} className="flex flex-col gap-4">
                                {item.links.map((link) => {
                                    return (
                                        <div key={link.id} className="flex flex-col gap-4">
                                            <p className="font-bold text-base text-sideBarTitle tracking-wider uppercase">
                                                {item.title}
                                            </p>
                                            {approvedRole(link.role) && (
                                                <div className="font-md font-semibold capitalize">
                                                    <NavLink
                                                        to={`/home/${link.route}`}
                                                        className={({ isActive }) =>
                                                            isActive ? activeLink : normalLink
                                                        }
                                                    >
                                                        {link.title}
                                                    </NavLink>
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default Sidebar
