import Keycloak from 'keycloak-js'

const keycloakSettings = {
    url: 'https://auth.paygate.kz/auth/',
    realm: 'microservices-realm',
    clientId: 'admin-react-app',
}

const _kc = new Keycloak(keycloakSettings)

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const updateToken = (successCallback) => _kc.updateToken().then(successCallback).catch(doLogin)

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role))

function initKeycloak(authCallback) {
    _kc.init({
        onLoad: 'login-required',
    }).then((loggedIn) => {
        if (loggedIn) {
            authCallback()
        } else {
            console.log('Not Authenticated')
            doLogin()
        }
    })
}

const KeycloakService = {
    initKeycloak,
    doLogin,
    doLogout,
    getToken,
    updateToken,
    hasRole,
}

export default KeycloakService
