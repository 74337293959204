export const adminLinks = [
    // {
    //     title: 'E-wallets',
    //     links: [
    //         {
    //             id: 0,
    //             route: 'e-wallets',
    //             name: 'e-wallet',
    //             role: 'KCELL_AGENT_ROLE',
    //         },
    //     ],
    // },
    {
        title: 'Таблицы',
        links: [
            {
                id: 1,
                route: 'agent-table',
                name: 'table',
                role: 'KCELL_AGENT_ROLE',
                title: 'История транзакций',
            },
        ],
    },
]
