//? Импорт React компонентов и хуков
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'

//? Импорты модулей и стилей AG-GRID
import { AgGridReact } from 'ag-grid-react'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { ModuleRegistry } from '@ag-grid-community/core'
import './GridTable.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

//? Импорты модулей для экспорта данных
import { csvToObj } from 'csv-to-js-parser'
import * as XLSX from 'xlsx/xlsx.mjs'

//? Функция создания полей таблицы
import { createField } from './GridMethods'

//? Регистрация модулей AG-GRID
ModuleRegistry.registerModules([CsvExportModule])

function GridTable({ tablesData }) {
    const gridRef = useRef()

    //? State переменные таблицы
    const [rowData, setRowData] = useState()
    const [columnDefs, setColumnDefs] = useState([])
    const [initialData, setInitialData] = useState(tablesData.data)
    const [buttonVisible, setButtonVisible] = useState(false)

    useEffect(() => {
        setInitialData(tablesData.data)
    }, [tablesData])

    //? Функции экспорта данных
    function onBtnExportCsv() {
        gridRef.current.api.exportDataAsCsv()
    }

    function onBtnExportXlsx(saveAll) {
        const csv = gridRef.current.api.getDataAsCsv()
        let csvData = csvToObj(csv)
        const workbook = XLSX.utils.book_new()
        const workSheet = XLSX.utils.json_to_sheet(csvData)
        XLSX.utils.book_append_sheet(workbook, workSheet, 'Table')
        if (saveAll && saveAll.type !== 'click') {
            const workSheetPage = XLSX.utils.json_to_sheet(exportAllObjectArray())
            XLSX.utils.book_append_sheet(workbook, workSheetPage, 'Table page')
        }
        XLSX.writeFileXLSX(workbook, 'Paygate')
    }

    function onBtnExportAllXlsx() {
        onBtnExportXlsx(true)
    }

    function exportAllObjectArray() {
        const keyDataObj = initialData[0]
        const keysDataArray = Object.keys(keyDataObj)
        const objectArray = []
        keysDataArray.map((key) => {
            if (Array.isArray(keyDataObj[key])) {
                return initialData.map((item) => {
                    item[key].map((obj) => {
                        obj.global_tag_id = item.global_tag_id
                        return objectArray.push(obj)
                    })
                })
            }
        })
        return objectArray
    }

    //? Функция изменения размера колонок
    function columnSizingHandler(item) {
        if (item.value.length > 27 && item.column.actualWidth >= 250 && item.column.actualWidth <= 280) {
            gridRef.current.api.sizeColumnsToFit()
            gridRef.current.columnApi.autoSizeColumn(item.column, false)
        } else {
            gridRef.current.api.sizeColumnsToFit()
        }
    }

    //? Отрисовка данных массива внутри объекта
    function objectArrayHandler(data, key) {
        const objectArray = data[key]
        setInitialData(objectArray)
        setButtonVisible(true)
    }

    useEffect(() => {
        if (initialData.length === 0) {
            return
        } else {
            setRowData(initialData)
            setColumnDefs(createField(initialData, objectArrayHandler))
        }
    }, [initialData])

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        minWidth: 350,
    }))

    return (
        <>
            <div className="w-full rounded-t-[10px] py-[15px] px-[18px] bg-light-gray border-1 border-solid border-[#59499a40]">
                {buttonVisible && (
                    <div className="flex gap-[20px]">
                        <h2 className="py-[10px] text-black font-[700] text-[16px]">Wallet operations</h2>
                        <button
                            type="button"
                            className="
             max-w-[140px]
             bg-[#6FCF97]  rounded-[10px] py-[12px] px-[10px] justify-center items-center font-[700] text-[13px] text-white cursor-pointer hidden lg:flex"
                            onClick={() => {
                                setInitialData(tablesData.data)
                                setButtonVisible(false)
                            }}
                        >
                            Основные данные
                        </button>
                    </div>
                )}
                {!buttonVisible && <h2 className="py-[10px] text-black font-[700] text-[16px]">Таблица операций</h2>}
            </div>
            <div className="relative ag-theme-alpine w-full h-[690px]">
                <AgGridReact
                    onCellClicked={(item) => {
                        if (!item.column.colDef.button) {
                            columnSizingHandler(item)
                        }
                    }}
                    pagination={true}
                    paginationPageSize={13}
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    rowSelection="multiple"
                />
                <div
                    className="absolute bottom-[2px] left-[18px] mb-[2px] max-w-[400px] w-full z-[100]
        gap-[20px] items-center font-[700] 
         text-[13px] text-white hidden lg:flex"
                >
                    <button
                        className=" invisible hover:bg-white hover:text-black  transition-all   bg-[#20232A] rounded-[10px]  py-[12px] px-[20px]"
                        onClick={onBtnExportCsv}
                        style={{ marginBottom: '5px', fontWeight: 'bold' }}
                    >
                        CSV
                    </button>
                    <button
                        className="invisible hover:bg-white hover:text-black  transition-all  bg-[#20232A]   rounded-[10px]  py-[12px] px-[20px]"
                        onClick={onBtnExportXlsx}
                        style={{ marginBottom: '5px', fontWeight: 'bold' }}
                    >
                        XLSX
                    </button>
                    <button
                        className=" invisible hover:bg-white hover:text-black transition-all  bg-[#20232A]  rounded-[10px]  py-[12px] px-[20px]"
                        onClick={onBtnExportAllXlsx}
                        style={{ marginBottom: '5px', fontWeight: 'bold' }}
                    >
                        Экспорт всех данных
                    </button>
                </div>
            </div>
        </>
    )
}

export default GridTable
