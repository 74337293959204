import React from 'react'

export function createField(data, walletOperationsHandler) {
    const keyDataObj = data[0]
    const keysDataArray = Object.keys(keyDataObj)
    const baseField = []

    function checkNull(key) {
        if (keyDataObj[key] === null) {
            return true
        }
    }

    function checkUrl(url) {
        try {
            new URL(url)
            return true
        } catch {
            return false
        }
    }

    function checkDate(key) {
        if (key === 'transaction_created_at' || key === 'created-at' || key === 'created_at') {
            return true
        } else {
            return false
        }
    }

    function checkStatus(key) {
        if (key === 'status') {
            return true
        } else {
            return false
        }
    }

    function checkLength(key) {
        if (keyDataObj[key].length > 15) {
            return true
        } else {
            return false
        }
    }

    function checkObjectChild(key) {
        if (typeof keyDataObj[key] === 'object') {
            return true
        } else {
            return false
        }
    }

    function checkObjectArray(key) {
        if (Array.isArray(keyDataObj[key])) {
            return true
        } else {
            return false
        }
    }

    keysDataArray.map((key) => {
        const keyTextArray = {
            amountToday: 'БАЛАНС КОШЕЛЬКА ЗА СЕГОДНЯ',
            amountYesterday: 'БАЛАНС КОШЕЛЬКА ЗА ВЧЕРАШНИЙ ДЕНЬ',
            paidAmount: 'СУММА СПИСАНИЙ',
            refundedAmount: 'CУММА ВОЗВРАТОВ',
            scrapAt: 'ДАТА',
            refundHoldAmount: 'ВОЗВРАТ ЗАМОРОЖЕННОЙ СУММЫ',
            holdAmount: 'ЗАМОРОЖЕННАЯ СУММА',
        }
        function keyInit(key) {
            keyTextArray[key]
        }

        // const keyRegular = key.replace(/_/g, ' ')
        const keyRegular = keyTextArray[key]
        const headerName = keyRegular[0].toUpperCase() + keyRegular.slice(1)
        if (checkNull(key)) {
            baseField.push({
                field: key,
                headerName: headerName,
            })
        } else if (checkDate(key)) {
            baseField.push({
                field: key,
                headerName: headerName,
                cellRenderer: (date) => {
                    return date.value ? new Date(date.value).toLocaleString() : ''
                },
            })
        } else if (checkUrl(keyDataObj[key])) {
            baseField.push({
                field: key,
                headerName: headerName,
                width: 300,
                cellRenderer: (url) => (
                    <a target="_blank" rel="noreferrer" href={url.value}>
                        {url.value}
                    </a>
                ),
            })
        } else if (checkLength(key)) {
            baseField.push({
                field: key,
                headerName: headerName,
                width: 400,
            })
        } else if (checkStatus(key)) {
            baseField.push({
                field: key,
                headerName: headerName,
                cellRenderer: (status) => {
                    if (status.value === 100) {
                        return (
                            <p className=" max-w-[70px] relative text-[16px] mt-[5px] text-center leading-[20px] font-[700] text-white bg-pg-green  rounded-[8px] py-[6px] z-[20]">
                                {status.value}
                            </p>
                        )
                    } else {
                        return (
                            <p className=" max-w-[70px] relative text-[16px] text-center leading-[20px] font-[700] text-white bg-red-500  rounded-[8px] py-[6px] z-[20]">
                                {status.value}
                            </p>
                        )
                    }
                },
            })
        } else if (checkObjectChild(key) && !checkObjectArray(key)) {
            const objectChild = keyDataObj[key]
            const objectChildKeys = Object.keys(objectChild)

            objectChildKeys.map((childKey) => {
                const keyRegular = childKey.replace(/_/g, ' ')
                const headerName = keyRegular[0].toUpperCase() + keyRegular.slice(1)

                if (checkDate(childKey)) {
                    return baseField.push({
                        field: `${key}.${childKey}`,
                        headerName: headerName,
                        cellRenderer: (date) => {
                            return date.value ? new Date(date.value).toLocaleString() : ''
                        },
                    })
                } else {
                    return baseField.push({
                        field: `${key}.${childKey}`,
                        headerName: headerName,
                    })
                }
            })
        } else if (checkObjectArray(key)) {
            baseField.push({
                item: key,
                headerName: headerName,
                button: true,
                cellRenderer: (item) => (
                    <button
                        type="button"
                        className="w-full relative text-[14px] mt-[5px] text-center leading-[20px] font-[700] text-black bg-base-blue  rounded-[8px] border border-solid border-borderGray py-[6px] z-[20]"
                        onClick={() => {
                            walletOperationsHandler(item.data, key)
                        }}
                    >
                        Подробнее
                    </button>
                ),
            })
        } else {
            return baseField.push({
                field: key,
                headerName: headerName,
            })
        }
    })

    return baseField
}
