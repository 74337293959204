import React, { createContext, useContext, useState } from 'react'

//? Импорт сервиса авторизации
import KeycloakService from '../../services/KeycloakService'

const AuthContext = createContext()

export function AuthContextProvider({ children }) {
    function logout() {
        KeycloakService.doLogout()
    }

    function approvedRole(role) {
        return KeycloakService.hasRole([role])
    }
    function getToken() {
        return KeycloakService.getToken()
    }

    return <AuthContext.Provider value={{ logout, approvedRole, getToken }}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => useContext(AuthContext)
