function Navbar({ logout, balance }) {
    return (
        <nav className="w-full flex justify-between items-center py-2 px-4 ">
            <p className="font-medium text-xl mx-auto">
                Текущий баланс:{' '}
                <span className="font-bold text-xxl">
                    {balance}
                    <span className="ml-[5px] text-[14px] font-bold">KZT</span>
                </span>
            </p>
            {/* <p className="font-bold text-xl">Текущий баланс</p> */}
            <button
                className="max-w-[40px] w-full py-[20px] rounded-full transition-all hover:bg-button-hover bg-logout bg-[length:20px_20px] bg-no-repeat bg-center"
                onClick={logout}
            />
        </nav>
    )
}
export default Navbar
