//? Импорт Реакт компонентов
import { Route, Routes } from 'react-router-dom'

//? Импорт компонентов
import ControlComponent from '../ControlComponent/ControlComponent'
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage'

function Main({ pagesArray, approvedRole }) {
    return (
        <div className="w-full max-height-[900px] overflow-y-scroll px-10 py-5 scroll_content">
            <Routes>
                {pagesArray.map((item) => {
                    return item.links.map((page) => {
                        return (
                            <Route
                                path={page.route}
                                element={
                                    <>
                                        <h2 className="text-[40px] font-bold mb-[20px]">{item.title}</h2>
                                        <ControlComponent
                                            component={page.component}
                                            approvedRole={approvedRole}
                                            role={page.role}
                                        />
                                    </>
                                }
                            />
                        )
                    })
                })}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </div>
    )
}
export default Main
