//? Импорт компонентов
import Wallet from '../../components/Wallet/Wallet'

//? Импорт контекста
import { useDataContext } from '../../contexts/DataContext/ContextProvider'

function WalletsPage() {
    //? Массив данных кошельков
    const { walletsData } = useDataContext()

    //? Массив операций по кошельку
    const operationMethods = [
        {
            id: 1,
            name: 'Top up your wallet',
            method: addBalance,
            methodName: 'add balance',
        },
        {
            id: 2,
            name: 'Withdraw from wallet',
            method: withdrawBalance,
            methodName: 'withdraw balance',
        },
        {
            id: 3,
            name: 'Transfer to another wallet',
            method: transferBalance,
            methodName: 'transfer balance',
        },
    ]

    //? Функции операций по кошельку
    function addBalance() {
        console.log('Add balance')
    }

    function withdrawBalance() {
        console.log('Remove balance')
    }

    function transferBalance() {
        console.log('Transfer balance')
    }

    return (
        <ul className="w-full flex justify-start items-start flex-wrap gap-4">
            {walletsData.wallets.map((wallet) => {
                return <Wallet key={wallet.id} wallet={wallet} operations={operationMethods} />
            })}
        </ul>
    )
}
export default WalletsPage
