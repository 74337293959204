import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './components/App/App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
//? Импорт контекста
import { ContextProvider } from './contexts/DataContext/ContextProvider'
import { AuthContextProvider } from './contexts/AuthContext/AuthContext'

//? Импорт сервиса авторизации
import KeycloakService from './services/KeycloakService'

const root = ReactDOM.createRoot(document.getElementById('root'))
const renderApp = () =>
    root.render(
        <React.StrictMode>
            <AuthContextProvider>
                <ContextProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ContextProvider>
            </AuthContextProvider>
        </React.StrictMode>,
    )
KeycloakService.initKeycloak(renderApp)
reportWebVitals()
