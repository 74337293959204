import React, { createContext, useContext, useState } from 'react'

const StateContext = createContext()

export function ContextProvider({ children }) {
    const [walletsData, setWalletsData] = useState([])
    const [tablesData, setTablesData] = useState({ data: [] })

    return (
        <StateContext.Provider value={{ walletsData, setWalletsData, tablesData, setTablesData }}>
            {children}
        </StateContext.Provider>
    )
}

export const useDataContext = () => useContext(StateContext)
