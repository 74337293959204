import { useEffect, useState } from 'react'

//? Импорты компонентов
import Operation from '../Operation/Operation'

function Wallet({ wallet, operations }) {
    const operator = 'PAYGATE'

    //? Стейт переменная стилей кошелька по умолчанию
    const [walletStyle, setwalletStyle] = useState({
        textColor: '#00264B',
        plagueColor: '#00264B',
        plagueTextColor: '#fff',
        fill: '#fff',
        border: '#00264B',
    })
    //? Стейт переменная видимости операций с кошельком
    const [operationVisible, setOperationVisible] = useState(false)

    //? Определение стиля кошелька
    useEffect(() => {
        if (wallet.walletName === 'Kcell') {
            setwalletStyle({
                textColor: '#652D86',
                plagueColor: '#652D86',
                plagueTextColor: '#fff',
                fill: '#fff',
                border: '#652D86',
            })
        } else if (wallet.walletName === 'PAYGATE') {
            setwalletStyle({
                textColor: '#6FCF97',
                plagueColor: '#4A3DAB',
                plagueTextColor: '#fff',
                fill: '#fff',
                border: '#4A3DAB',
            })
        }
    }, [])

    return (
        <li
            className={`max-w-[470px] w-full min-h-[280px]  flex border border-solid bg-white rounded-[30px] overflow-hidden hover:shadow-cardShadow hover:-translate-y-1 transition-all`}
            style={{ borderColor: walletStyle.border, color: walletStyle.textColor }}
        >
            <div
                className="relative w-[15%] flex flex-col-reverse items-center px-2 pb-8 "
                style={{ background: walletStyle.plagueColor, color: walletStyle.plagueTextColor }}
            >
                <svg
                    className=" -rotate-90"
                    height="30px"
                    width="30px"
                    viewBox="0 0 26 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M15.9634 3.1877C16.1356 2.97039 16.4516 2.9346 16.668 3.1079L17.7487 3.97351L13.7982 9.00004H16.3419L19.3097 5.22389L21.8142 7.22997C22.0353 7.40702 22.0653 7.73213 21.8804 7.94665L20.9728 8.99976H23.5869C24.2916 7.93783 24.0861 6.48718 23.0646 5.66898L17.9183 1.54691C16.8365 0.680403 15.2562 0.859399 14.3957 1.94591L8.80859 8.99976H11.36L15.9634 3.1877ZM18 18C17.4477 18 17 18.4477 17 19C17 19.5523 17.4477 20 18 20H21C21.5523 20 22 19.5523 22 19C22 18.4477 21.5523 18 21 18H18ZM3 6C1.34315 6 0 7.34315 0 9V23.5004C0 25.9857 2.01472 28.0004 4.5 28.0004H21.5C23.9853 28.0004 26 25.9857 26 23.5004V14.5004C26 12.0152 23.9853 10.0004 21.5 10.0004L3 10C2.44772 10 2 9.55228 2 9C2 8.44772 2.44772 8 3 8H7.58L9.17625 6H3ZM2 23.5004V11.8293C2.31278 11.9398 2.64936 12 3 12H6.46974C6.47979 12.0003 6.48988 12.0004 6.5 12.0004H21.5C22.8807 12.0004 24 13.1197 24 14.5004V23.5004C24 24.8812 22.8807 26.0004 21.5 26.0004H4.5C3.11929 26.0004 2 24.8812 2 23.5004Z"
                        fill={walletStyle.fill}
                    />
                </svg>
                <p className="absolute bottom-[95px] left-0 w-full font-bold text-[25px] leading-7 -rotate-90 text-white ">
                    Wallet
                </p>
            </div>
            <div className="w-[85%] flex flex-col gap-[90px] py-8">
                <div className="flex flex-col gap-2 px-5">
                    <h2 className="text-4xl font-bold">{wallet.walletName}</h2>
                    <p
                        className="w-fit text-sm font-bold p-2  rounded-[10px] "
                        style={{ background: walletStyle.plagueColor, color: walletStyle.plagueTextColor }}
                    >
                        {wallet.type}
                    </p>
                </div>
                <div className=" flex flex-col ">
                    <div className="flex justify-between px-5">
                        <p className="text-4xl font-semibold">
                            {wallet.moneyAmount} <span className="text-xs font-bold">KZT</span>
                        </p>
                        <button
                            className="max-w-[40px] w-full rounded-full transition-all hover:bg-button-hover"
                            onClick={() => {
                                setOperationVisible(!operationVisible)
                            }}
                        >
                            <svg
                                className={`w-6 h-6 m-auto ${operationVisible ? '' : '-rotate-[90deg]'} transition-all`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="4"
                                    d="M19 9l-7 7-7-7"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <ul
                        className={`w-full ${
                            operationVisible ? 'max-h-[700px] opacity-100 mt-4' : 'max-h-[0px] opacity-0'
                        }  transition-all `}
                    >
                        {operations.map((operation) => {
                            return <Operation key={operation.id} styles={walletStyle} operation={operation} />
                        })}
                    </ul>
                </div>
            </div>
        </li>
    )
}
export default Wallet
