import { useState } from 'react'

function Operation({ styles, operation }) {
    const [formVisible, setFormVisible] = useState(false)

    return (
        <li key={operation.id}>
            <div className="flex flex-col gap-4">
                <p
                    className="w-full flex justify-between text-base font-semibold leading-10 py-2 px-5 border-t border-solid"
                    style={{ borderColor: styles.border }}
                >
                    {operation.name}
                    <button
                        type="button"
                        className="max-w-[40px] w-full rounded-full  transition-all hover:bg-button-hover"
                        onClick={() => {
                            setFormVisible(!formVisible)
                        }}
                    >
                        <svg
                            className={`w-6 h-6 m-auto ${formVisible ? '' : '-rotate-[90deg]'} transition-all`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="4"
                                d="M19 9l-7 7-7-7"
                            ></path>
                        </svg>
                    </button>
                </p>
            </div>
            <form className={`w-full ${formVisible ? 'max-h-[200px]' : 'max-h-[0px]'} overflow-hidden transition-all`}>
                <label className="flex flex-col p-4">
                    <p className="font-semibold uppercase  text-[14px] text-[#212529] opacity-40 pb-1">
                        {operation.methodName}
                    </p>
                    <label className="flex gap-2">
                        <input
                            type="number"
                            placeholder="Enter the amount"
                            className="w-full font-medium text-[14px] text-black border border-solid outline-none p-2 rounded-[10px]"
                            style={{ borderColor: styles.border }}
                        />
                        <button
                            type="submit"
                            className="max-w-[40px] w-full rounded-10px bg-[#08A835] rounded-[10px] bg-pay bg-[length:20px_20px] bg-no-repeat bg-center  hover:scale-[1.2] transition-all"
                            onClick={(evt) => {
                                evt.preventDefault()
                                operation.method()
                            }}
                        ></button>
                    </label>
                </label>
            </form>
        </li>
    )
}
export default Operation
