//? Импорт компонентов
import { useEffect, useState } from 'react'
import GridTable from '../../components/GridTable/GridTable'

//? Импорт контекста
import { useDataContext } from '../../contexts/DataContext/ContextProvider'

function TablePage() {
    const { tablesData } = useDataContext()

    return (
        <div className="w-full">
            <GridTable tablesData={tablesData} />
        </div>
    )
}
export default TablePage
